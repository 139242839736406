<template>
  <div>
    <title-bar title="Benutzer anlegen">
      <router-link slot="right" to="/users" class="button is-primary">
        <b-icon
          :icon="$func.getIcon('back').icon"
          :pack="$func.getIcon('back').pack"
          class="mr-1"
        />
        Benutzer Übersicht
      </router-link>
    </title-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Details"
          :icon="$func.getIcon('user').icon"
          :icon-pack="$func.getIcon('user').pack"
          class="tile is-child"
        >
          <form @submit.prevent="submitUser">
            <b-field label="Name" horizontal>
              <b-input
                v-model="user.name"
                placeholder="e.g. Max Mustermann"
                minlength="2"
                required
              />
            </b-field>

            <b-field label="E-Mail" horizontal>
              <b-input
                v-model="user.email"
                placeholder="name@domain.de"
                type="email"
              />
            </b-field>

            <b-field label="Passwort" horizontal :type="passwordMatch">
              <b-input
                v-model="user.password"
                name="password"
                type="password"
                minlength="8"
                autocomplete="new-password"
                placeholder="********"
              />
            </b-field>
            <b-field
              label="Passwort bestätigen"
              horizontal
              :type="passwordMatch"
            >
              <b-input
                v-model="user.password_confirmation"
                name="password_confirmation"
                type="password"
                minlength="8"
                autocomplete="new-password"
                placeholder="********"
              />
            </b-field>

            <hr />

            <b-field horizontal>
              <b-button
                type="is-primary"
                native-type="submit"
                :icon-left="$func.getIcon('save').icon"
                :icon-pack="$func.getIcon('save').pack"
              >
                Benutzer anlegen
              </b-button>
            </b-field>
          </form>
        </card-component>
      </tiles>
    </section>

    <b-loading :is-full-page="true" :active="isLoading" />
  </div>
</template>

<script>
import api from "@/utils/api.js";
import TitleBar from "@/components/TitleBar";
import Tiles from "@/components/Tiles";
import CardComponent from "@/components/CardComponent";

export default {
  name: "UserNew",
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      user: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null
      }
    };
  },
  computed: {
    passwordMatch() {
      if (this.user.password !== this.user.password_confirmation) {
        return "is-danger";
      } else {
        return "";
      }
    }
  },
  methods: {
    submitUser() {
      if (this.user.password !== this.user.password_confirmation) {
        this.$buefy.toast.open({
          message: `Fehler: Passwörter stimmen nicht überein`,
          type: "is-danger",
          queue: false
        });
      } else {
        this.isLoading = true;
        api
          .post(`/users`, this.user)
          .then(() => {
            this.$buefy.snackbar.open({
              message: "Benutzer angelegt",
              queue: false
            });
            this.$router.push({ name: "users" });
          })
          .finally(() => (this.isLoading = false));
      }
    }
  }
};
</script>
