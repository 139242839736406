var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "title-bar",
        { attrs: { title: "Benutzer anlegen" } },
        [
          _c(
            "router-link",
            {
              staticClass: "button is-primary",
              attrs: { slot: "right", to: "/users" },
              slot: "right"
            },
            [
              _c("b-icon", {
                staticClass: "mr-1",
                attrs: {
                  icon: _vm.$func.getIcon("back").icon,
                  pack: _vm.$func.getIcon("back").pack
                }
              }),
              _vm._v(" Benutzer Übersicht ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "section is-main-section" },
        [
          _c(
            "tiles",
            [
              _c(
                "card-component",
                {
                  staticClass: "tile is-child",
                  attrs: {
                    title: "Details",
                    icon: _vm.$func.getIcon("user").icon,
                    "icon-pack": _vm.$func.getIcon("user").pack
                  }
                },
                [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submitUser.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "b-field",
                        { attrs: { label: "Name", horizontal: "" } },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "e.g. Max Mustermann",
                              minlength: "2",
                              required: ""
                            },
                            model: {
                              value: _vm.user.name,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "name", $$v)
                              },
                              expression: "user.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "E-Mail", horizontal: "" } },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "name@domain.de",
                              type: "email"
                            },
                            model: {
                              value: _vm.user.email,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "email", $$v)
                              },
                              expression: "user.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Passwort",
                            horizontal: "",
                            type: _vm.passwordMatch
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              name: "password",
                              type: "password",
                              minlength: "8",
                              autocomplete: "new-password",
                              placeholder: "********"
                            },
                            model: {
                              value: _vm.user.password,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "password", $$v)
                              },
                              expression: "user.password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Passwort bestätigen",
                            horizontal: "",
                            type: _vm.passwordMatch
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              name: "password_confirmation",
                              type: "password",
                              minlength: "8",
                              autocomplete: "new-password",
                              placeholder: "********"
                            },
                            model: {
                              value: _vm.user.password_confirmation,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "password_confirmation", $$v)
                              },
                              expression: "user.password_confirmation"
                            }
                          })
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "b-field",
                        { attrs: { horizontal: "" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                "native-type": "submit",
                                "icon-left": _vm.$func.getIcon("save").icon,
                                "icon-pack": _vm.$func.getIcon("save").pack
                              }
                            },
                            [_vm._v(" Benutzer anlegen ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-loading", {
        attrs: { "is-full-page": true, active: _vm.isLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }